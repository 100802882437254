<!-- src/components/Signup.vue -->

<template src="@/../../../templates/brand/components/templates/user/Activate.html"> </template>

<script>
import _ from 'lodash/fp'
import _c from '@/../../../templates/site/UI/common/common-constants'

export default {
  data () {
    return {
      reset: ~this.$route.path.indexOf('reset'),
      title: '',
      credentials: {
        // username: '',
        password: ''
      },
      unamevalid: false,
      confirm: '',
      error: '',
      successmsg: ''
    }
  },
  computed: {
    invalid: function () {
      // if(~this.$route.path.indexOf('reset'))
      return !(
        this.credentials.password.length > 5 &&
        this.credentials.password === this.confirm
      )
      // 	else
      // 		return !(this.credentials.password.length > 6 && this.credentials.password === this.confirm) || !this.credentials.username;
    }
  },
  methods: {
    submit () {
      let credentials
      if (~this.$route.path.indexOf('activate')) {
        credentials = {
          email: this.$route.query.email,
          // username: this.credentials.username,
          password: this.credentials.password
        }
      } else {
        credentials = {
          email: this.$route.query.email,
          password: this.credentials.password
        }
      }
      this.$http.post(`public/users/activate/${this.$route.query.id}`, credentials)
        .then(() => {
          this.successmsg = this.$t('client.activate.passwordUpdated')
          setTimeout(() => {
            this.$router.push('/login')
          }, 3000) // or however many seconds you want to wait
        })
        .catch(err => {
          this.error = err
        })
    },
    testUsername: function () {
      if (!this.credentials.username) return
      this.$http
        .get(`${_c.PUBLIC_URL}/users/validusername/${this.credentials.username}`)
        .then(res => {
          this.unamevalid = _.isEmpty(res.data)
        })
        .catch(err => {
          console.error('got an error', err)
        })
    }
  },
  created () {
    if (!this.$route.query.email || !this.$route.query.id) this.$router.push('/')
    this.$i18n.locale = this.$route.query.lang
    if (~this.$route.path.indexOf('activate'))
      this.title = this.$t('client.activate.setPassword')
    else this.title = 'Please reset your password to activate your account'
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/user/Activate.css"></style>
