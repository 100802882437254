<!-- src/components/Login.vue -->

<template src="@/../../../templates/brand/components/templates/user/Login.html"> </template>

<script>
import { mapActions, mapGetters } from 'vuex'
import auth from '@/util/auth'
import validator from 'validator'
// import { PUBLIC_URL } from '@/util/common-constants'

export default {
  created: function () {
    this.$watch('error', function () {
      if (this.error) {
        this.flashErr = 'Sorry, email or password is incorrect.'
      }
    })
    console.info(' ::: locales', this.$root.$i18n)
  },
  data () {
    return {
      lang: 'en',
      showPassword: false,
      reset: false,
      resetSuccess: false,
      resetMsg: '',
      credentials: {
        username: '',
        password: ''
      },
      error: '',
      flashErr: ''
    }
  },
  computed: {
    ...mapGetters({
      appconfig: 'const'
    }),
    isValid: function () {
      return (
        !this.isEmpty(this.credentials.username) &&
        !this.isEmpty(this.credentials.password) &&
        this.isEmail(this.credentials.username)
      )
    },
    isIE: function () {
      // test for old IE
      try {
        return parseInt(navigator.userAgent.match(/(MSIE |Trident.*rv[ :])([0-9]+)/)[2], 10)
      } catch (e) {
        return 0
      }
    }
  },
  methods: {
    ...mapActions({ dologin: 'completeLogin'}),
    changeLang (l) {
      const locale = l ? l : this.$root.$i18n.locale === 'en' ? 'fr' : 'en'
      document.documentElement.lang = locale
      this.$root.$i18n.locale = locale
    },
    isEmail: function (val) {
      return validator.isEmail(val)
    },
    isEmpty: function (val) {
      return validator.isEmpty(val)
    },
    submit () {
      console.info(' :: login actions', this.$store)
      if (!this.isValid) return false
      var credentials = {
        email: validator.normalizeEmail(this.credentials.username, {
          gmail_remove_dots: false
        }),
        password: this.credentials.password
      }
      // We need to pass the component's this context
      // to properly make use of http in the auth service
      auth.login(this, credentials, 'redirect?', (err, user, redirect) => {
        if (err) {
          this.error = err
        } else {
          console.info(' :: did auth now call vuex login', user)
          this.dologin(user) // dispatch vuex action, puts user on store.
            .then(() => {
              this.$router.replace(redirect || '/') // this should call redirect. Have to catch the route that got us here...
            })
        }
      })
    },
    resetpass () {
      if (this.isEmail(this.credentials.username)) {
        this.$http
          .get(`public/users/${this.credentials.username}/reset`)
          .then(res => {
            console.info('this is res', res)
            this.resetSuccess = res.success
            // this.resetMsg = res[0].msg;
            this.resetMsg = this.$t('client.login.receivePassword')
          })
          .catch (() => {
            this.resetMsg = this.$t('client.login.resetMsg')
          })
      }
    },
    // changeLang: function () {
    //   if (this.$i18n.locale === 'en') this.activate('fr')
    //   else this.activate('en')
    // }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/user/Login.css"></style>
